<template>
  <div id="about">
    <Header/>
    <div id="service_top">
      <div class=" w1400 breadcrumbs">
        <span><a href="index.php">首頁</a></span> > 關於Wi-Ho!
      </div>
      <div class="inner w1300 txt-black">
        <div class="title txt-center sr_right">
          <img src="@/assets/img/logo.svg" alt="" style="width:200px;">
          <h2 class="h2-super_title txt-bold">
            國際Wi-Fi通訊服務品牌 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
          </h2>
        </div>
      </div>
    </div>
    <div id="banner">
      <div class="bg">
        <figure
          :style="`background-image: url(${require('@/assets/img/about/img.jpg')});`">
        </figure>
      </div>
    </div>
    <div class="bageset">
        <div class="bage">
          <figure
            :style="`background-image: url(${require('@/assets/img/index/bage_1.png')});`">
          </figure>
        </div>
        <div class="bage">
          <figure
            :style="`background-image: url(${require('@/assets/img/index/bage_2.png')});`">
          </figure>
        </div>
        <div class="bage">
          <figure
            :style="`background-image: url(${require('@/assets/img/index/bage_3.png')});`">
          </figure>
        </div>
    </div>
    <div class="block">
      <div class="inner w1200">
        <h6 class="txt-white">
          TELECOMSQUARE 成立於 1974 年的專業國際行動通訊服務公司，並在 1992 年領先日本同業界最早開始行動電話租賃事業。<br>
          之後在 2010 年成立 Ｗi-Ho! 品牌，並提供 Wi-Fi 分享器、SIM卡、eSIM 等出國上網服務。
          至今台灣 Wi-Ho!® 已突破 100 萬人使用體驗，成為國際 Wi-Fi 通訊服務品牌
          <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="" style="width: 10px;">
        </h6>
      </div>
    </div>
    <div id="history">
      <div class="inner w1400">
        <h3 class="h2-super_title txt-bold">
          公司沿革
          <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
        </h3>
        <div
          class="itembox"
          :style="`background-image: url(${require('@/assets/img/about/deco.png')});`">
            <div class="item_scroll">
              <div class="cardbox">
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2014</h4>
                  <div class="card">
                    <div class="text">
                      <h6>桃園機場櫃位設置，T1&T2共5個櫃位</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2013</h4>
                  <div class="card">
                    <div class="text">
                      <h6>台灣特樂通股份有限公司成立</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2012</h4>
                  <div class="card">
                    <div class="text">
                      <h6>
                        海外定額行動數據通信月租開始緬甸専用行動電話租借開始中部國際機場ＭＯＢＩＬＥモ中心開設、
                        主要機場的12個地方可自由「取機」「還機」世界76個國家・地域一台Wi-Fi分享器就可對應的『Wi-Ho!®』ＢＵＳＩＮＥＳＳ周遊機提供開始
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2011</h4>
                  <div class="card">
                    <div class="text">
                      <h6>
                        含Wi-Fi分享器功能的智慧手機『ＳＭＡＲＴ Wi-Ho!®』開始Ｅ・ＭＯＢＩＬＥ（現Ｅ･ＡＣＣＥＳＳ）合作開始，
                        並在成田機場因受E・MOBILE 商店委託成立新物流中心
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                    <h4 class="cardtitle txt-bold">2010</h4>
                    <div class="card">
                        <div class="text">
                            <h6>定額制行動Wi-Fi分享器『Wi-Ho!®』與ＪＣＢ一起合作、開始服務ＪＣＢ客服</h6>
                        </div>
                    </div>
                </div>
                <div class="time_item">
                    <h4 class="cardtitle txt-bold">2009</h4>
                    <div class="card">
                        <div class="text">
                            <h6>開始長距離通信服務「ＷＯＲＬＤＬＩＮＫ」折扣服務</h6>
                        </div>
                    </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2008</h4>
                  <div class="card">
                    <div class="text">
                      <h6>與中國百度及ＷＩＬＬＣＯＭ的合作、開始服務來日中國人。Nokia從撤退關閉Nokia store</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2007</h4>
                  <div class="card">
                    <div class="text">
                      <h6>
                        福岡機場內設立直營櫃檯，與ＮＴＴ DATA合作との提携により、
                        成立危機管理即時共同回報系統 開始『Global Cast』服務。
                        成立專業電話客服中心子公司、ＴＳ SUPPORT（現ASSIT ALL）
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2006</h4>
                  <div class="card">
                    <div class="text">
                      <h6>與ＮＯＫＩＡ ＪＡＰＡＮ株式会社合作、開設ＮＯＫＩＡ專賣店「Nokia store東京車站八重洲」福岡門市開設</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2005</h4>
                  <div class="card">
                    <div class="text">
                      <h6>24小時日語・英語電話服務開始名古屋門市開設</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2004</h4>
                  <div class="card">
                    <div class="text">
                      <h6>關西國際機設立直營櫃檯限SIM卡門市租借開始全日空官方指定供應商</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2003</h4>
                  <div class="card">
                    <div class="text">
                      <h6>成立美國公司更名為株式会社TELECOM SQUARE美・中・歐等24小時日本語支援體制完成成田空機場内設置物流中心</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2002</h4>
                  <div class="card">
                    <div class="text">
                      <h6>
                        成立成田機場第一家專業租借櫃檯成田機場內、同業界首發的無預約臨櫃租借開始成田空港內、同業界首發的還機時立即精算開始世界杯足球賽桌上設置電話翻譯開始
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">2000</h4>
                  <div class="card">
                    <div class="text">
                      <h6>開始成田機場租賃業務</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">1996</h4>
                  <div class="card">
                    <div class="text">
                      <h6>開始海外替代品送抵服務</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">1995</h4>
                  <div class="card">
                    <div class="text">
                      <h6>西武新宿站、設立直營門市</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">1992</h4>
                  <div class="card">
                    <div class="text">
                      <h6>開始一日租借業務</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">1988</h4>
                  <div class="card">
                    <div class="text">
                      <h6>開始日本移動通信株式会社（現KDDI）行動電話販賣業務、開始法人業務新電電的電話線路業務</h6>
                    </div>
                  </div>
                </div>
                <div class="time_item">
                  <h4 class="cardtitle txt-bold">1974</h4>
                  <div class="card">
                    <div class="text">
                      <h6>設立獨立係通信商社、ＰＡＲＴＥＲＬＩＮＫ株式会社</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';

import '@/assets/scss/about.scss';

export default {
  name: 'Terms',
  components: {
    Header,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  watch: {
    $route() {
      if (this.$route.query.history === '1') {
        setTimeout(() => {
          document.querySelector('#history').scrollIntoView(true);
        }, 300);
      }
    },
  },
  mounted() {
    if (this.$route.query.history === '1') {
      setTimeout(() => {
        document.querySelector('#history').scrollIntoView(true);
      }, 300);
    }
  },
};
</script>
